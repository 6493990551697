import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class MainHeaderNavbar extends Vue {
  get routes(): { path: string, name: string, active?: string[] }[] {
    const routes = [
      { path: '/', name: 'Home', active: ['/', '/home'] },
      { path: '/helpdesk', name: 'Supporto', active: ['/helpdesk', '/helpdesk'] },
    ];

    return routes.map(m => {
      if (!m.active)
        m.active = [m.path];
      return m;
    })
  }
}