export default {
  route: {
    dashboard: `Dashboard`
  },
  navbar: {
    logOut: `Esci`,
    dashboard: `Dashboard`,
    github: `Github`,
    theme: `Tema`,
    size: `Grandezza Globale`,
    profile: `Profilo`
  },
  common: {
    delete: 'Cancella',
    confirm: 'Conferma',
    cancel: 'Annulla',
    uploadedFiles: 'File caricati',
    inQualitadi: {
      proprietarioterreno: 'Proprietario Terreno',
      legalerappresentante: 'Legale Rappresentante',
      altri: 'Altro',
      utilizzatore: 'Utilizzatore',
      titolaredittaindividuale: 'Titolare - Ditta individuale',
      affittuario: 'Affittuario'
    },
    salvataggio: {
      success: 'Procedimento salvato con Successo',
      error: 'Errore nella presentazione del procedimento'
    }
  },
  users: {
    search: `Cerca`,
    accountLabel: `Account:`,
    createUserTitle: `Inserisci utente`,
    createUserLogin: `Login`,
    createUserLoginTip: `Inserisci Login`,
    createUserEmail: `Email`,
    createUserPhone: `Numero di telefono`,
    createUserFiscalCode: `Codice Fiscale`,
    userExistsTitle: `Attenzione`,
    userExistsBody: `Utente Esistente`,
    updateUserButton: `Aggiorna`,
    removeUserButton: `Elimina`
  },
  errorPage: {
    back: `Torna alla Home`,
    401: {
      title: `Non sei autorizzato ad accedere`,
      description: `Siamo spiacenti, l'accesso alla pagina richiesta con le sue credenziali è stato negato.`
    },
    404: {
      title: `Pagina non trovata`,
      description: `Siamo spiacenti, ma non è stato possibile trovare la pagina richiesta.`
    }
  },
  cookies: {
    cookies: 'Questo sito utilizza cookie tecnici e analytics.',
    navigazione: 'Proseguendo nella navigazione accetti l’utilizzo dei cookie.'
  },
  canoni: {
    tipoComponente: {
      fissa: 'Fissa',
      aggiuntiva: 'Aggiuntiva'
    }
  },
  pagamenti: {
    pagoPA: 'Richiedi un pagamento con PagoPA',
    manuale: 'Richiedi un pagamento manuale: si dovrà allegare la ricevuta di pagamento'
  },
  prelievi: {
    tipologia: {
      concessione: 'Concessione',
      licenzaattingimento: 'Licenza di attingimento'
    },
    concessione: {
      nuova: 'Nuova concessione',
      rinnovo: 'Rinnovo concessione'
    },
    variazioneRichiesta: {
      ordinariaconcessione: 'Concessione ordinaria',
      cambiotitolarita: 'Cambio titolarità',
      variazionesostanziale: 'Variazione sostanziale',
      variazionenonsostanziale: 'Variazione non sostanziale',
      usodiverso: 'Uso diverso',
      irriguosopra: 'Irriguo sopra i 5000 mq',
      irriguosotto: 'Irriguo sotto i 5000 mq',
      clonalicenza: 'Clona licenza'
    },
    stato: {
      bozza: 'Bozza',
      preistruttoria: 'Preistruttoria',
      presentata: 'Presentato',
      verifica: 'In Verifica',
      attesaintegrazione: 'In Attesa di Integrazione',
      archiviazione: 'Archiviato',
      rilascio: 'Permesso Rilasciato',
      lavorazione: 'In Lavorazione'
    },
    statoIcona: {
      bozza: `<svg class="mx-1 icon icon-info"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-bookmark"></use></svg>`,
      preistruttoria: `<svg class="mx-1 icon icon-info"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-pa"></use></svg>`,
      presentata: `<svg class="mx-1 icon icon-info"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-files"></use></svg>`,
      verifica: `<svg class="mx-1 icon icon-info"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-warning-circle"></use></svg>`,
      attesaintegrazione: `<svg class="mx-1 icon icon-warning"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-info-circle"></use></svg>`,
      archiviazione: `<svg class="mx-1 icon icon-warning"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-box"></use></svg>`,
      rilascio: `<svg class="mx-1 icon icon-success"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-check-circle"></use></svg>`,
      lavorazione: `<svg class="mx-1 icon icon-warning"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-pencil"></use></svg>`
    },
    sistemaIrrigazione: {
      pioggiaLabel: 'SISTEMA A PIOGGIA',
      pioggiaText: `Indicare le fasce orarie per un massimo di <strong>6 ore</strong> settimanali nei giorni feriali consentiti <strong>(tranne che dalle ore 12.00 alle ore 17.00)</strong>`,
      gocciaLabel: 'SISTEMA A GOCCIA',
      gocciaText: `Indicare le fasce orarie per un massimo di <strong>12 ore</strong> settimanali nei giorni feriali`
    },
    calendario: {
      giorni: {
        lunedi: 'Lunedì',
        martedi: 'Martedì',
        mercoledi: 'Mercoledì',
        giovedi: 'Giovedì',
        venerdi: 'Venerdì',
        sabato: 'Sabato',
        domenica: 'Domenica'
      },
      title: 'Fasce orarie',
      subtitle: "Indicare le fasce orarie in cui si intende effettuare l'attingimento",
      notabene:
        " Nota bene: è vietato l'attingimento il sabato ad eccezione dei seguenti corsi d'acqua e corpi idrici sotterranei che ricadono nella fascia dei 100 metri dalla sponda degli stessi: Tevere, Campiano, Clitunno, Corno a valle della confluenza con il Sordo, Timia a valle della confluenza con il Clitunno, Nera, Sordo, Vigi, Chiascio a valle della diga di Casanova.",
      danger: 'Attenzione!',
      error: 'Il massimo delle ore settimanali è di ore:',
      maxHours: 'Massimo ore:',
      counterHours: 'Conteggio ore totali:',
      day: 'Giorno',
      from: 'Dalle ore',
      to: 'Alle ore',
      add: 'Aggiungi nuova fascia oraria'
    },
    datiCatastali: {
      comune: 'Comune',
      localita: 'Località',
      foglio: 'Foglio',
      particella: 'Particella',
      subalterno: 'Subalterno',
      add: 'Aggiungi nuovi dati catastali'
    },
    tipoUso: {
      industriale: 'Industriale',
      zootecnico: 'Zootecnico',
      piscicolturaittiogenico: 'Ittiogenico',
      accumuloinvernaleinvaso: 'Accumulo invernale invaso',
      irrigazioneareeverdi: 'Irrigazione verde',
      irrigazioneimpiantisportivi: 'Irrigazione impianti sportivi',
      irriguo: 'Irriguo',
      abbeveraggioanimalicortile: 'Abbeveraggio animali cortile',
      altro: 'Altro',
      idropotabile: 'Potabile',
      beniservizi: 'Produzione beni servizi',
      geotermico: 'Geotermico',
      forzamotrice: 'Forza motrice',
      idroelettrico: 'Idroelettrico (piccole derivazioni)',
      domestico: 'Domestico',
      irrigazioneagricola: 'Agrituristico',
      nonspecificato: 'Non specificato',
      igienico: 'Igienico',
      autolavaggio: 'Auto lavaggio',
      lavaggiomacchinari: 'Lavaggio macchinari',
      lavaggiostradepiazzaliecontrollopolveriinerti: 'Lavaggio strade/piazzali e controllo polveri inerti',
      pescasportiva: 'Pesca sportiva',
      antincendio: 'Antincendio',
      trattamentiantiparassitari: 'Trattamentianti parassitari',
      usovenatorio: 'Uso venatorio',
      forzamotriceidroelettricograndiderivazioni: 'Forza motrice idroelettrico grandi derivazioni',
      forzamotriceidroelettricopiccolederivazioni: 'Forza motrice idroelettrico piccole derivazioni',
      abbattimentopolveri: 'Abbattimento polveri'
    },
    tipoPagamentiFissi: {
      generico: 'Generico',
      marcadabollo: 'Marca da Bollo',
      speseistruttoria: 'Spese di istruttoria'
    },
    tipologiaAllegatiCorto: {
      CartaDiIdentità: 'Carta di identità',
      DocumentazioneFotografica: 'Documentazione fotografica',
      Planimetria: 'Planimetria',
      GiustificazioneNonProprietari: 'Giustificazione non proprietari',
      AutorizzazioneDelProprietario: 'Autorizzazione del proprietario',
      AutorizzazioneInvaso: 'Autorizzazione invaso',
      ParereEnteParco: 'Parere ente parco',
      SpeseIstruttoria: 'Spese istruttoria',
      RelazioneTecnica: 'Relazione tecnica ',
      Corografia: 'Corografia',
      richiestaPagamento: 'Richiesta Pagamento',
      rispostaPagamento: 'Risposta Pagamento',
      allegato: 'Allegato',
      Bollo: 'Marca da Bollo',
      ElaboratiTecnici: 'Elaborati tecnici',
      RicevutaPagoPa: 'Ricevuta PagoPA',
      SCIA: 'SCIA',
      Cartografia: 'CARTOGRAFIA - MAPPE CATASTALI',
      ComplessiIdrogeologici: 'TAVOLA N. 5',
      StatoChimico: 'TAVOLA N. 10a PTA2',
      StatoQuantitativo: 'TAVOLA N. 10b PTA2',
      Allegato7: 'ALLEGATO 7',
      IndirizziOperativi: 'Indirizzi operativi',
      AllegatoA: 'ALLEGATO A',
      Tavola14: 'TAVOLA N. 14 PTA2',
      Allegato4: 'ALLEGATO IV PARTE SECONDA',
      richiestaComunicazioni: 'Allegato Comunicazione',
      rispostaComunicazioni: 'Risposta Comunicazione',
      CartaDiIdentitaCambioTitolarita: 'Carta di identità',
      CambioTitolarita: 'Cambio di titolarità',
      ParereNatura2000: 'Parere Natura2000',
      CartaDiIdentitaVariazione: 'Documento di identità;',
      CartaTecnicaRegionale: 'Carta tecnica regionale',
      EstrattoPlanimetria: 'Planimetria catastale',
      RelazioneTecnicaGenerale: 'Relazione tecnica',
      PianteProspettiSezioni: 'Piante, prospetti, sezioni',
      RilascioLicenzaAttingimento: 'Rilascio Licenza Attingimento',
      RilascioConcessione: 'Rilascio Concessione',
      RichiestaLicenzaAttingimento: 'Richiesta Licenza Attingimento Firmata',
      RichiestaConcessione: 'Richiesta Concessione Firmata',
      AvvisoAnalogico: 'Avviso Analogico'
    },
    tipologiaAllegati: {
      CartaDiIdentità: 'Copia di un documento di identità del richiedente',
      DocumentazioneFotografica: 'Documentazione fotografica del tratto di alveo/sponda interessata dalla collocazione della pompa di attingimento',
      Planimetria: 'Planimetrie in scala 1/2000 in cui sono evidenziate le particelle interessate ed il punto di prelievo delle acque',
      GiustificazioneNonProprietari: "Per i non proprietari allegare titolo che giustifichi l'istanza (contratto di affitto, comodato o altro)",
      AutorizzazioneDelProprietario: 'Autorizzazione del proprietario dei terreni dove è collocata la condotta di adduzione, qualora diverso dal richiedente',
      AutorizzazioneInvaso: "Per coloro che utilizzano un invaso allegare l'autorizzazione alla realizzazione dello stesso",
      ParereEnteParco: "Parere favorevole all'attingimento rilasciato dall'ente parco",
      SpeseIstruttoria: 'Ricevute versamento: spese di istruttoria €. 30,00 e canone',
      RelazioneTecnica:
        "Relazione tecnica illustrativa sul tipo di insediamento, sul consumo previsto nell'arco dell'anno, sull'uso, sulle modalità di captazione dell'acqua e sull'aventuale restituzione della stessa",
      Corografia: "Corografie in scala 1/25000 in cui è evidenziata la zona di prelievo dell'acqua",
      allegato: 'Allegato',
      Bollo: 'Marca da Bollo da 16€',
      ElaboratiTecnici: 'Elaborati tecnici a firma di un professionista abilitato completi degli elementi riportati nella “Scheda A - pozzi” (relazione tecnica, relazione geologica etc.',
      RicevutaPagoPa: 'Ricevuta PagoPA del versamento delle spese di istruttoria indicate nella D.G.R. n. 1670 del 29/12/2015 (1/40 del canone)',
      SCIA: 'Copia della SCIA presentata al Comune',
      Cartografia: 'CARTOGRAFIA IGM 1:25.000 - MAPPE CATASTALI 1:2000',
      ComplessiIdrogeologici: 'TAVOLA N. 5 PTA2 - Complessi idrogeologici',
      StatoChimico: 'TAVOLA N. 10a PTA2 - Stato chimico corpi idrici (aggiornamento con D.G.R. n. 1311 del 13.12.2023)',
      StatoQuantitativo: 'TAVOLA N. 10b PTA2 - Stato quantitativo corpi idrici',
      Allegato7: 'ALLEGATO 7 D.G.R. n. 925/2003',
      IndirizziOperativi: 'ALLEGATO 7 - APPENDICE ALLA DIRETTIVA “Indirizzi operativi”',
      AllegatoA: 'ALLEGATO A - D.G.R. N. 495/2023',
      Tavola14: 'TAVOLA N. 14 PTA2 - Aree di salvaguardia',
      Allegato4: 'ALLEGATO IV PARTE SECONDA D.Lgs 152/2006',
      CartaDiIdentitaCambioTitolarita: 'Fotocopia dei documenti di identità (del dichiarante e del Legale Rappresentante della Società subentrante); ',
      CambioTitolarita:
        'Documentazione comprovante il cambio di titolarità (copia conforme dell’atto di compravendita, affitto, fusione, dichiarazione di successione, donazione, ecc. oppure dichiarazione sostitutiva di certificazione o di atto di notorietà ai sensi degli artt 46 e 47 del DPR 445/2000)',
      ParereNatura2000: `Parere favorevole all’attingimento ai sensi della direttiva 92/43/CEE, del DPR 357/97 e della LR 1/2015 rilasciato dal Servizio Foreste, montagna, sistemi naturalistici e Faunistica-venatoria della Regione Umbria`,
      CartaDiIdentitaVariazione: 'Fotocopia di un documento di identità;',
      CartaTecnicaRegionale: 'Estratto della carta tecnica regionale (CTR 1:10.000) con la localizzazione della/e opera/e di variante;',
      EstrattoPlanimetria: 'Estratto della planimetria catastale (1:2.000) con la localizzazione della/e opera/e di variante;',
      RelazioneTecnicaGenerale: 'Relazione tecnica generale descrittiva della/e opera/e di variante a firma di un tecnico iscritto al relativo Albo o Ordine Professionale;',
      PianteProspettiSezioni:
        'Piante, prospetti, sezioni e particolari costruttivi in scala 1:100, o in scala adeguata, delle opere relative alla variante alla concessione, a firma di un tecnico abilitato;',
      RilascioLicenzaAttingimento: 'Rilascio Licenza Attingimento',
      RilascioConcessione: 'Rilascio Concessione',
      RichiestaLicenzaAttingimento: 'Richiesta Licenza Attingimento Firmata',
      RichiestaConcessione: 'Richiesta Concessione Firmata',
      AvvisoAnalogico: 'Avviso Analogico'
    },
    tipologiaAllegatiConcessioniCorto: {
      common: {
        CartaDiIdentità: 'Carta Di Identità'
      },
      superficiali: {
        SchedaFiltro: 'Scheda filtro',
        VisuraCamerale: 'Visura camerale',
        RelazioneTecnica: 'Relazione Tecnica',
        ElaboratiGrafici: 'Elaborati Grafici',
        CalcoloSommario: 'Calcolo sommario della spesa e piano finanziario',
        DichiarazioneProprieta: 'Dichiarazione di proprietà dei terreni',
        DichiarazioneConformitaPesca: 'Dichiarazione di conformità per impianti ittiogenici e pesca sportiva',
        DichiarazioneImpedimento: 'Dichiarazione di impedimento all’uso del servizio di rete pubblica',
        DichiarazioneAssenso: 'Dichiarazione di assenso del proprietario',
        AutorizzazioniEdilizie: 'Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare'
      },
      superficialiIdroelettriche: {
        SchedaFiltro: 'Scheda filtro',
        VisuraCamerale: 'Visura camerale',
        RelazioneTecnica: 'Relazione tecnica',
        DisegniTecnici: 'Disegni tecnici',
        Misure: 'Misure, dimensionamento e verifiche',
        ElaboratiCartografici: 'Elaborati cartografici',
        DichiarazioneProprieta: 'Dichiarazione di proprietà dei terreni',
        ComputoMetrico: 'Computo metrico estimativo e piano finanziario',
        DichiarazioneImpedimento: 'Dichiarazione di impedimento all’uso del servizio di rete pubblica',
        DichiarazioneAssenso: 'Dichiarazione di assenso del proprietario',
        AutorizzazioniEdilizie: 'Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare'
      },
      sorgenti: {
        SchedaFiltro: 'Scheda filtro',
        VisuraCamerale: 'Visura camerale',
        RelazioneTecnica: 'Relazione tecnica ',
        RelazioneGeologica: 'Relazione geologica e idrogeologica',
        ElaboratiGrafici: 'Elaborati grafici',
        DichiarazioneProprieta: 'Dichiarazione di proprietà dei terreni',
        AutorizzazioniEdilizie: 'Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare',
        DichiarazioneImpedimento: 'Dichiarazione di impedimento all’uso del servizio di rete pubblica',
        Idoneita: 'Idoneità all’uso potabile',
        DichiarazioneConformitaPesca: 'Dichiarazione di conformità per impianti ittiogenici e pesca sportiva',
        DichiarazioneAssenso: 'Dichiarazione di assenso del proprietario'
      },
      sorgentiIdroelettriche: {
        SchedaFiltro: 'Scheda filtro',
        VisuraCamerale: 'Visura camerale',
        RelazioneTecnica: 'Relazione tecnica',
        RelazioneGeologica: 'Relazione geologica e idrogeologica',
        DisegniTecnici: 'Disegni tecnici',
        ElaboratiCartografici: 'Elaborati cartografici',
        Misure: 'Misure, dimensionamento e verifiche',
        ComputoMetrico: 'Computo metrico estimativo e piano finanziario',
        DichiarazioneProprieta: 'Dichiarazione di proprietà dei terreni',
        AutorizzazioniEdilizie: 'Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare',
        DichiarazioneAssenso: 'Dichiarazione di assenso del proprietario'
      },
      sotterranee: {
        RelazioneGeologica: 'Relazione geologica e idrogeologica',
        RelazioneTecnica: 'Relazione tecnica illustrativa',
        ElaboratiGrafici: 'Elaborati grafici e visura catastale',
        VisuraCamerale: 'Visura Camerale',
        TitoliAbitativi: 'Titoli abitativi relativi alle opere',
        DichiarazioneImpedimento: 'Dichiarazione di impedimento all’uso del servizio di rete pubblica',
        DichiarazioneProprieta: 'Dichiarazione o atto di proprietà'
      },
      cambiotitolarita: {
        CartaDiIdentitaTitolareUscente: 'Cambio Titolarità - Carta Di Identità Titolare Uscente',
        CartaDiIdentitaSubentrante: 'Cambio Titolarità - Carta Di Identità Subentrante',
        CambioTitolarita: 'Cambio Titolarità - Documentazione comprovante il cambio di titolarità',
        MarcaDaBollo: 'Cambio Titolarità - Marca da Bollo'
      },
      variazione: {
        CartaDiIdentitaVariazione: 'Variazione - Documento di identità',
        CartaTecnicaRegionale: 'Variazione - Carta Tecnica Regionale',
        EstrattoPlanimetria: 'Variazione - Estratto Planimetria',
        RelazioneTecnicaGenerale: 'Variazione - Relazione Tecnica Generale',
        PianteProspettiSezioni: 'Variazione - Piante Prospetti Sezioni'
      },
      ParereNatura2000: 'Parere Natura2000',
      allegato: 'Allegato',
      RilascioConcessione: 'Rilascio Concessione',
      RichiestaConcessione: 'Richiesta Concessione Firmata',
      RichiestaCambioTitolarita: 'Richiesta Cambio Titolarità',
      RichiestaVariazioneSostanziale: 'Richiesta Variazione Sostanziale',
      RichiestaVariazioneNonSostanziale: 'Richiesta Variazione Non Sostanziale'
    },
    tipologiaAllegatiConcessioni: {
      common: {
        CartaDiIdentità: 'Copia di un documento di identità del richiedente'
      },
      superficiali: {
        SchedaFiltro: 'Compilazione della scheda filtro dell’Autorità di Bacino Distrettuale',
        VisuraCamerale: 'Visura camerale',
        RelazioneTecnica: 'Rif. scheda C/grandi derivazioni, lettere A, I, J, N, O, S, V oppure Rif. scheda C/piccole derivazioni, lettere A, H, I, M, N, R, U',
        ElaboratiGrafici: 'Rif. scheda C/grandi derivazioni, lettere B, C, D, E, G, H, Q oppure Rif. scheda C/piccole derivazioni, lettere B, C, D, E, F, G, P',
        CalcoloSommario: 'Calcolo sommario della spesa e piano finanziario',
        DichiarazioneProprieta:
          'Dichiarazione sostitutiva o certificazione attestante la proprietà dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque',
        DichiarazioneConformitaPesca: 'Progettazione conforme alla L.R. 15/2008 per la realizzazione di opere concernenti il funzionamento di un impianto ittiogenico o di pesca sportiva',
        DichiarazioneImpedimento:
          'Dichiarazione che il fabbisogno idrico per l’uso richiesto non è soddisfatto da reti destinate all’approvvigionamento per lo stesso uso (L.R. 33/2004, D.G.R. 925/2003)',
        DichiarazioneAssenso:
          'Dichiarazione del proprietario del terreno attestante l’assenso a quanto richiesto (qualora il richiedente la concessione sia un soggetto diverso dal titolare dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque)',
        AutorizzazioniEdilizie: 'Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare connesse alla derivazione'
      },
      superficialiIdroelettriche: {
        SchedaFiltro: 'Compilazione della scheda filtro dell’Autorità di Bacino Distrettuale',
        VisuraCamerale: 'Visura camerale',
        RelazioneTecnica: 'Rif. scheda D, lettera A',
        DisegniTecnici: 'Rif. scheda D, lettere C.8, C.9, C.10, C.11 e disegni delle principali opere',
        Misure: 'Rif. scheda D lettera B',
        ElaboratiCartografici: 'Rif. scheda D lettere da C.1 a C.7, C.12, C.13',
        DichiarazioneProprieta:
          'Dichiarazione sostitutiva o certificazione attestante la proprietà dei terreni sui quali insisteranno le opere di raccolta, regolazione, adduzione, presa e restituzione delle acque e la proprietà di tutti i terreni sui quali insisteranno le opere a servizio dell’impianto idroelettrico',
        ComputoMetrico: 'Computo metrico estimativo e piano finanziario',
        DichiarazioneImpedimento:
          'Dichiarazione che il fabbisogno idrico per l’uso richiesto non è soddisfatto da reti destinate all’approvvigionamento per lo stesso uso (L.R. 33/2004, D.G.R. 925/2003)',
        DichiarazioneAssenso:
          'Dichiarazione del proprietario del terreno attestante l’assenso a quanto richiesto (qualora il richiedente la concessione sia un soggetto diverso dal titolare dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque)',
        AutorizzazioniEdilizie: 'Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare connesse alla derivazione'
      },
      sorgenti: {
        SchedaFiltro: 'Compilazione della scheda filtro dell’Autorità di Bacino Distrettuale',
        VisuraCamerale: 'Visura camerale',
        RelazioneTecnica: 'Rif. scheda B lettere B, E',
        RelazioneGeologica: 'Rif. scheda B lettera A',
        ElaboratiGrafici: 'Rif. scheda B lettera A.10',
        DichiarazioneProprieta: 'Rif. scheda B lettera C',
        AutorizzazioniEdilizie: 'Rif. scheda B lettera G',
        DichiarazioneImpedimento: 'Rif. scheda B lettera H',
        Idoneita: 'Rif. scheda B lettera I',
        DichiarazioneConformitaPesca: 'Progettazione conforme alla L.R. 15/2008 per la realizzazione di opere concernenti il funzionamento di un impianto ittiogenico o di pesca sportiva',
        DichiarazioneAssenso:
          'Dichiarazione del proprietario del terreno attestante l’assenso a quanto richiesto (qualora il richiedente la concessione sia un soggetto diverso dal titolare dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque)'
      },
      sorgentiIdroelettriche: {
        SchedaFiltro: 'Compilazione della scheda filtro dell’Autorità di Bacino Distrettuale',
        VisuraCamerale: 'Visura camerale',
        RelazioneTecnica: 'Rif. scheda B lettere B, E e Rif. scheda D lettera A',
        RelazioneGeologica: 'Rif. scheda B lettera A',
        DisegniTecnici: 'Rif. scheda D, lettere C.8, C.9, C.10, C.11 e disegni delle principali opere',
        ElaboratiCartografici: 'Rif. scheda B lettera A.10 e Rif. scheda D lettere da C.1 a C.7, C.12, C.13',
        Misure: 'Rif. scheda D lettera B',
        ComputoMetrico: 'Computo metrico estimativo e piano finanziario',
        DichiarazioneProprieta:
          'Dichiarazione sostitutiva o certificazione attestante la proprietà dei terreni sui quali insisteranno le opere di raccolta, regolazione, adduzione, presa e restituzione delle acque e la proprietà di tutti i terreni sui quali insisteranno le opere a servizio dell’impianto idroelettrico',
        AutorizzazioniEdilizie: 'Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare connesse alla derivazione',
        DichiarazioneAssenso:
          'Dichiarazione del proprietario del terreno attestante l’assenso a quanto richiesto (qualora il richiedente la concessione sia un soggetto diverso dal titolare dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque)'
      },
      sotterranee: {
        RelazioneGeologica: 'NE: Rif. scheda A, lettere da A.1 ad A.6 <br> OPE: Rif. scheda A, lettere da A.1 ad A.7',
        RelazioneTecnica: 'NE: Rif. scheda A, lettere da B.1 a B.11, F <br> OPE: Rif. scheda A, lettere da B.1 a B.11, F',
        ElaboratiGrafici: 'NE: Rif. scheda A, lettere da C.1 a C.8, D <br> OPE: Rif. scheda A, lettere da C.1 a C.8, D',
        VisuraCamerale: 'NE: Rif. scheda A, lettera E <br> OPE: Rif. scheda A, lettera E',
        TitoliAbitativi: 'NE: Rif. scheda A, letera H <br> OPE: Rif. scheda A, lettera H',
        DichiarazioneImpedimento:
          'Dichiarazione che il fabbisogno idrico per l’uso richiesto non è soddisfatto da reti destinate all’approvvigionamento per lo stesso uso (L.R. 33/2004, D.G.R. 925/2003) <br> NE: Rif. scheda A, lettera I <br> OPE: Rif. scheda A, lettera I',
        DichiarazioneProprieta: 'NE: Rif. scheda A, lettera J <br> OPE: Rif. scheda A, lettera J'
      },
      cambiotitolarita: {
        CartaDiIdentitaTitolareUscente: 'Carta Di Identità del Titolare Uscente',
        CartaDiIdentitaSubentrante: 'Carta Di Identità del Subentrante',
        CambioTitolarita:
          'Copia conforme dell’atto di compravendita, affitto, fusione, dichiarazione di successione, donazione, ecc. oppure dichiarazione sostitutiva di certificazione o di atto di notorietà ai sensi degli artt. 46 e 47 del DPR 445/2000',
        MarcaDaBollo: 'Marca da Bollo'
      },
      variazione: {
        CartaDiIdentitaVariazione: 'Documento di identità;',
        CartaTecnicaRegionale: 'Estratto della carta tecnica regionale (CTR 1:10.000) con la localizzazione della/e opera/e di variante',
        EstrattoPlanimetria: 'Estratto della planimetria catastale (1:2.000) con la localizzazione della/e opera/e di variante;',
        RelazioneTecnicaGenerale: 'Relazione tecnica generale descrittiva della/e opera/e di variante a firma di un tecnico iscritto al relativo Albo o Ordine Professionale;',
        PianteProspettiSezioni:
          'Piante, prospetti, sezioni e particolari costruttivi in scala 1:100, o in scala adeguata, delle opere relative alla variante alla concessione, a firma di un tecnico abilitato;'
      },
      ParereNatura2000: 'Parere Natura2000',
      allegato: 'Allegato',
      RilascioConcessione: 'Rilascio Licenza Concessione',
      RichiestaConcessione: 'Richiesta Concessione Firmata',
      RichiestaCambioTitolarita: 'Richiesta Cambio Titolarità',
      RichiestaVariazioneSostanziale: 'Richiesta Variazione Sostanziale',
      RichiestaVariazioneNonSostanziale: 'Richiesta Variazione Non Sostanziale'
    },
    puntoDiDerivazione: {
      fiume: 'Fiume',
      torrente: 'Torrente',
      fosso: 'Fosso',
      canale: 'Canale',
      lago: 'Lago',
      invaso: 'Invaso',
      sorgente: 'Sorgente',
      pozzo: 'Pozzo',
      diga: 'Diga',
      traversa: 'Traversa',
      acquedotto: 'Acquedotto',
      torbiera: 'Torbiera',
      bacinoartificiale: 'Bacino artificiale',
      stagnopalude: 'Stagno/Palude',
      acquifero: 'Acquifero',
      strumento: {
        tipo: {
          pompa: 'Pompa',
          tubo: 'Tubo (per caduta)',
          trattorebotte: 'Trattore/Botte',
          condotta: 'Condotta',
          canale: 'Canale',
          idrovora: 'Idrovora'
        },
        trasportabilita: {
          fisso: 'Fisso',
          semifisso: 'Semifisso',
          mobile: 'Mobile'
        }
      },
      corpiIdrici: {
        bacini: 'Bacini',
        corpiidricifluviali: 'Corpi idrici fluviali',
        corpiidricisuperficiali: 'Corpi idrici superficiali',
        corpiidricisotterranei: 'Corpi idrici sotterranei'
      }
    },
    colture: {
      nome: 'Nome',
      superficie: 'Superficie in ettari (ha)',
      percentuale: 'Percentuale (%)',
      add: 'Aggiungi una nuova coltura'
    },
    ripristino: {
      richiedenti: 'Richiedenti',
      usi: 'Usi',
      attributi: "Attributi dell'uso",
      puntiDiDerivazione: 'Punti di derivazione',
      datiprelievo: 'Dati del prelievo',
      richiedenti_des: 'Lista completa di tutti  i richiedenti e le loro aziende',
      usi_des: 'Usi e informazioni aggiuntive degli usi',
      attributi_des: 'Colture,Tipo di irrigazione',
      puntiDiDerivazione_des: 'Punti di prelievo e restituzione, dati del pozzo, dati catastali, contatori, strumenti di prelievo',
      datiprelievo_des: 'Volumi e portate'
    },
    dichiarazioni: {
      sottoscrittoDichiara: `<strong>Il/La sottoscritto/a</strong>, consapevole delle sanzioni e delle conseguenze penali in caso di dichiarazioni mendaci o false attestazioni ai sensi del D.P.R. n° 445 del 28-12-2000, <strong>DICHIARA:</strong>`,
      rispettareDisposizioni:
        "Di rispettare tutte le disposizioni che saranno impartite anche successivamente al rilascio della licenza (limitazioni, sospensioni, revoca) e farle rispettare da eventuali addetti all'esercizio della pompa, nonchè di esercitare l'attingimento nel rispetto dell'art. 56 del R.D. 11 Dicembre 1933, n.1775 e s.m.e. i., di tutta la normativa vigente in materia e delle prescrizioni indicate nella licenza concessa;",
      zoneFrega: "Che in relazione alle zone di frega, osserverà il divieto di prelievo idrico per il periodo di due mesi dalla data dell'istituzione del vincolo;",
      prelievoRicade: 'Che il prelievo ricade',
      areaParco: 'in area parco;',
      areaSICSIRZPS: 'in area Natura 2000 (SIC-ZSC e ZPS);',
      corpoIdrico: 'Che il corpo idrico in oggetto',
      consumoUmano: 'fra quelli le cui acque sono riservate al consumo umano;',
      utilizzoAcqua: `Di utilizzare l'acqua esclusivamente per le finalità richieste in licenza e non la cederà a terzi. Tale disposizione non si applica ai soggetti deputati alla gestione del servizio irriguo descritto all'Allegato A - Punto 13. "Prescrizioni generali da rispettare" Lett. m della D.G.R. 1551/2016;`,
      flussoVitale: 'Di garantire il rispetto del minimo flusso vitale;',
      bollo: 'Che la marca da bollo, apposta alla presente domanda, è esclusivamente utilizzata per la presente richiesta di attingimento.',
      dichiarazioniMendaci: `Il sottoscritto dichiara che quanto sopra indicato corrisponde al vero, consapevole che le dichiarazioni mendaci, la falsità in atti e l'uso di atti falsi sono puniti ai sensi del Codice Penale e delle Leggi speciali in materia e comportano la decadenza dai benefici eventualmente conseguiti ai sensi dell’art. 75 del DPR 445/2000.`,
      sfruttamentoRisorsa: `Il sottoscritto è inoltre consapevole che la presentazione della richiesta di concessione non comporta di per sé il diritto allo 
      sfruttamento della risorsa, che potrà avvenire solo dopo il rilascio da parte dell’Autorità concedente del provvedimento 
      concessorio e che è facoltà della stessa la revoca della concessione in qualunque momento per accertata incompatibilità con 
      gli obiettivi di tutela dei corpi idrici superficiali e sotterranei interessati senza che ciò possa dar luogo alla corresponsione di 
      indennizzi da parte della Amministrazione concedente.`,
      modificheMedesime:
        'Il sottoscritto dichiara che salve le modifiche qui richieste, tutte le altre caratteristiche dell’utenza sono le medesime di quelle assentite con l’atto di concessione originario richiamato in premessa e nel relativo disciplinare di concessione;',
      canoniAnnuali:
        'Il sottoscritto dichiara di avere provveduto ad effettuare il versamento dei canoni annuali dovuti per l’utilizzo della risorsa, come indicato nell’atto di concessione citato in premessa;',
      ulterioriAllegati:
        'Il sottoscritto dichiara che provvederà ad inoltrare a codesta Amministrazione eventuali ulteriori elaborati tecnici e documenti che siano ritenuti necessari per lo svolgimento del procedimento istruttorio relativo alla variante sostanziale alla concessione di derivazione idrica richiesta.',
      ulterioriAllegatiNon:
        'Il sottoscritto dichiara che provvederà ad inoltrare a codesta Amministrazione eventuali ulteriori elaborati tecnici e documenti che siano ritenuti necessari per lo svolgimento del procedimento istruttorio relativo alla variante non sostanziale alla concessione di derivazione idrica richiesta.'
    },
    impegno: {
      misuratori: 'A mantenere in perfetta efficienza i misuratori volumetrici verificandone il funzionamento;',
      cartellino: "A mantenere esposto sulla pompa per tutto il periodo dell'attingimento il cartellino rilasciato dalla Regione Umbria con gli estremi identificativi della licenza;",
      volumi: "A comunicare i volumi idrici prelevati nell'anno in corso entro il 31 Dicembre;",
      attrezzature: 'A rimuovere, tutte le attrezzature, entro 30 giorni dal termine di scadenza della licenza con conseguente rimessa in pristino dei luoghi.'
    },
    informativa: {
      titolo: 'INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI PER IL CASO DI DATI RACCOLTI DIRETTAMENTE PRESSO L’INTERESSATO AI SENSI DELL’ART. 13 REG. (UE) 2016/679',
      sottotitolo: 'Licenze di attingimento e concessioni di derivazione idrica',
      body: `Ai sensi dell’art. 13 Reg. (UE) 2016/679 “Regolamento del Parlamento europeo relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati e che abroga la direttiva 95/46/CE (regolamento generale sulla protezione dei dati)” si forniscono le seguenti informazioni in relazione al trattamento dei dati personali richiesti ai fini della gestione delle pratiche di licenze di attingimento e di concessioni di derivazione idrica nonché della gestione della relativa piattaforma (archivio digitale e gestione iter). 
      <br /><br />
      <strong> 1.	Identità e dati di contatto del Titolare del trattamento e del legale rappresentante (art. 13, par. 1, lett. a) Reg. (UE) 2016/679):</strong>
      <br/>Titolare del trattamento dei dati è la Regione Umbria – Giunta regionale, con sede in Corso Vannucci 96 – 06121 Perugia (Email: infogiunta@regione.umbria.it; PEC: regione.giunta@postacert.umbria.it; Centralino: +39 075 5041), nella persona del suo legale rappresentante, il Presidente della Giunta Regionale;
      <br/><br/>
      <strong> 2.	Dati di contatto del responsabile della protezione dei dati (art. 13, par. 1, lett. b) Reg. (UE) 2016/679)</strong>
      <br/>Il Titolare ha provveduto a nominare il Responsabile della protezione dei dati i cui contatti sono pubblicati nel Sito Web istituzionale www.regione.umbria.it/privacy1.
      <br/><br/>
      <strong> 3. Modalità, base giuridica del trattamento dei dati (art. 13, par. 1, lett. c) Reg. (UE) 2016/679) e finalità del trattamento:</strong>
      <br/>La Regione Umbria, in qualità di titolare, tratterà i dati personali conferiti con il presente modulo, con modalità prevalentemente informatiche. Il trattamento si fonda sulle seguenti basi giuridiche:
      <br/>
      -	esecuzione di un compito di interesse pubblico o connesso all’esercizio di un potere pubblico;<br/>
      -	esecuzione di un obbligo legale;<br/>
      -	esecuzione di un contratto o di una fase precontrattuale di cui sia parte l’interessato. <br/><br/>
      Il trattamento è svolto per le seguenti finalità: <br/>
      -	gestione dell’intero iter procedimentale delle derivazioni idriche;<br/>
      -	realizzazione e gestione di un database integrale delle derivazioni idriche;<br/>
      -	realizzazione di un sistema decisionale basato sui dati di prelievo che tenga conto della valutazione del bilancio idrico dei corpi d’acqua superficiali e sotterranei.<br/>
      <br/><br/>
      <strong> 4.	Categorie di dati personali in questione (art. 14, par. 1, lett. d) Reg. (UE) 2016/679)</strong>
      <br/> I dati personali trattati sono riportati di seguito.
      <br/> Dati comuni: dati anagrafici; indirizzo residenza; indirizzo e-mail; carta di identità - patente - passaporto; identità digitale - SPID, CNS; data di nascita; luogo di nascita; recapito telefonico; città e luogo di residenza; genere; cittadinanza; età; ragione o denominazione sociale; partita IVA; codice fiscale persona giuridica; identità professionale; codice fiscale.
      <br/><br/>
      <strong>5.	Obbligatorietà o facoltatività conferimento dati (art. 13, par. 2, lett. e) Reg. (UE) 2016/679)</strong>
      <br/>
      La comunicazione dei dati personali come specificati in questa informativa è obbligatoria e il mancato conferimento dei dati richiesti comporta l'impossibilità per la Regione Umbria di dare seguito alla richiesta di licenza di attingimento o di concessione di derivazione idrica.
      <br/><br/>
      <strong>6.	Eventuali destinatari o le eventuali categorie di destinatari dei dati personali (art. 13, par. 1, lett. e) Reg. (UE) 2016/679)</strong>
      <br/>
      All’interno dell’Amministrazione i dati saranno trattati dal personale e da collaboratori del Servizio e dei Servizi regionali coinvolti nel procedimento. <br/>
      All’esterno dell’Amministrazione i dati verranno trattati da soggetti espressamente nominati come Responsabili esterni del trattamento, i cui dati identificativi sono conoscibili tramite richiesta da avanzare al Responsabile per i Dati Personali. <br/>
      Al di fuori di queste ipotesi i dati non saranno comunicati a terzi né diffusi, se non nei casi specificamente previsti dal diritto nazionale o dell'Unione europea.
      <br/><br/>
      <strong>7.	Trasferimento dei dati personali a Paesi extra UE o a Organizzazioni internazionali (art. 13, par. 1, lett. f) Reg. (UE) 2016/679)</strong>
      <br/>
      I dati non saranno oggetto di trasferimento verso Paesi extra UE o Organizzazioni internazionali. <br/>
      Qualora i fornitori terzi dovessero risiedere al di fuori dello Spazio Economico Europeo (SEE), abbiamo concluso con essi accordi di servizio ai sensi dell’art. 28 e del Capo V del Reg. (UE) 2016/679. Tutti i fornitori sono conformi al Reg. (UE) 2016/679 e, in assenza di decisioni di adeguatezza o di altre misure di garanzia, abbiamo concluso con loro le Clausole Contrattuali Standard approvate dalla Commissione Europea per garantire adeguati livelli di tutela. Una copia delle garanzie poste in essere può essere richiesta in qualsiasi momento, scrivendo a dpo@regione.umbria.it. <br/>
      La comunicazione dei dati personali come specificati in questa informativa è obbligatoria.
      <br/><br/>
      <strong>8.	Periodo di conservazione dei dati personali (art. 13, par. 2, lett. a) Reg. (UE) 2016/679)</strong>
      <br/>
      I dati saranno trattati per il tempo necessario al perseguimento delle finalità per le quali sono stati raccolti. In particolare, i dati sono conservati per tutta la durata delle licenze/concessioni e per ulteriori 10 anni dopo la loro scadenza. Alla scadenza di tale periodo saranno conservati i dati relativi a prelievi e restituzioni per finalità di studio e ricerca previa cancellazione dei dati personali raccolti.
      <br/><br/>
      <strong>9.	Modifiche e aggiornamenti</strong>
      <br/>
      La presente informativa è valida a decorrere dal 01/10/2024.
      <br/>
      Regione Umbria potrebbe inoltre apportare modifiche e/o integrazioni a detta informativa, anche quale conseguenza di eventuali successive modifiche e/o integrazioni normative del Regolamento Privacy. Le modifiche, costantemente aggiornate, potranno essere visionate nella sezione dedicata del sito web istituzionale.
      `
    }
  },

  tipoValidita: {
    nuova: 'Nuova',
    rinnovo: 'Rinnovo'
  }
};
