import settings from '@/settings';
import { baseRestService } from '@/services/_base/baseRestService'

class UsersService extends baseRestService {
  constructor() {
    super();
    this.baseUrl = () => `${settings.authService.endpoint}/identity/`;
  }

  public async Remove(id: string): Promise<void> {
    return await this.Delete<void>(`users/${id}`);
  }

  async ConfirmEmail(confirmEmail: user.confirmEmail): Promise<{ pwToken: string }> {
    return this.Post<{ pwToken: string }>(`registration/confirmMail`, confirmEmail);
  }

  async ResetPassword(username: string, token: string, newPassword: string): Promise<any> {
    return this.Post<any>(`users/password/reset`,
      {
        username: username,
        token: token,
        newPassword: newPassword
      }
    );
  }

  public async getUsers(): Promise<user.summary[]> {
    return await this.Get<user.summary[]>("users");
  }

  public async getUsersByClaims(claims: string[]): Promise<user.summary[]> {
    return await this.Get<user.summary[]>("users/byClaims", { claims });
  }

  public async getUser(id: string): Promise<user.item> {
    return await this.Get<user.item>(`users/${id}`);
  }

  public async CreateUserViaEmail(user: user.registerUserViaEmail): Promise<user.item> {
    return await this.Post<user.item>(`registration`, user);
  }

  public async updateUser(user: user.item): Promise<user.item> {
    return await this.Put<user.item>(`users/${user.id}`, user);
  }

  public async resetPassword(user: user.summary | user.item, newpassword: string): Promise<void> {
    const data = new FormData();
    data.append("newpassword", newpassword);
    return await this.Post(`users/${user.id}/resetpassword`, data);
  }
  public async recoverPassword(user: user.summary | user.item, returnUri: string): Promise<void> {
    return await this.Get(`users/recoverpassword`, { username: user.userName, returnUri: returnUri });
  }

  public async changePassword(oldpassword: string, newpassword: string): Promise<void> {
    return await this.Post(`users/changepassword`, {}, { oldpassword, newpassword });
  }

  public validatePassword(passwd: string, confirmPassword: string): user.passwordValidation {
    let result: user.passwordValidation = {
      length: passwd.length >= 6,
      digit: /\d/.test(passwd),
      lowercase: /[a-z]/.test(passwd),
      uppercase: /[A-Z]/.test(passwd),
      special: /[^a-zA-Z0-9]/.test(passwd),
      equals: passwd == confirmPassword,
      isValid: false,
    };

    result.isValid = result.digit && result.length && result.lowercase && result.special && result.uppercase && result.equals;
    return result;
  }

  public async getUserRoles(user: user.summary | user.item): Promise<Array<string>> {
    return await this.Get(`users/${user.id}/roles`);
  }

  public async getMyRoles(): Promise<string[]> {
    return await this.Get<string[]>(`/users/roles`);
  }

  public getUserColor(username: string, opacity: number = 1): string {
    return '';
  }
}

export const usersService = new UsersService();
